'use client';

import {cn} from '@/lib/utils';
import * as TabsPrimitive from '@radix-ui/react-tabs';
import {forwardRef} from 'react';

const Tabs = forwardRef<React.ElementRef<typeof TabsPrimitive.Root>, React.ComponentPropsWithoutRef<typeof TabsPrimitive.Root>>(
    ({className, ...props}, ref) => (
        <TabsPrimitive.Root className={cn('px-18 rounded-xl bg-gradient-to-tr from-middleground to-middleground/30 py-8', className)} ref={ref} {...props} />
    )
);
Tabs.displayName = TabsPrimitive.Root.displayName;

const TabsList = forwardRef<React.ElementRef<typeof TabsPrimitive.List>, React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>>(
    ({className, ...props}, ref) => (
        <TabsPrimitive.List ref={ref} className={cn('flex h-10 items-center justify-center gap-12 rounded-md p-1 text-black', className)} {...props} />
    )
);
TabsList.displayName = TabsPrimitive.List.displayName;

const TabsTrigger = forwardRef<React.ElementRef<typeof TabsPrimitive.Trigger>, React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>>(
    ({className, ...props}, ref) => (
        <TabsPrimitive.Trigger
            ref={ref}
            className={cn(
                'z-10 mx-6 inline-flex items-center justify-center whitespace-nowrap font-medium ring-offset-primary transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:text-foreground md:text-base lg:text-xl',
                className
            )}
            {...props}
        />
    )
);
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

const TabsContent = forwardRef<React.ElementRef<typeof TabsPrimitive.Content>, React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>>(
    ({className, ...props}, ref) => (
        <TabsPrimitive.Content
            ref={ref}
            className={cn(
                'my-24 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
                className
            )}
            {...props}
        />
    )
);
TabsContent.displayName = TabsPrimitive.Content.displayName;

export {Tabs, TabsContent, TabsList, TabsTrigger};
