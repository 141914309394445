import type {DrupalNode, DrupalParagraph} from 'next-drupal';

import {COLOR_VARIANTS, PARAGRAPH_TYPES, STORAGE_KEYS} from '@/lib/constants';
import {cn, getFilters, getFiltersLink} from '@/lib/utils';
import {useTranslation} from 'next-i18next';
import {useEffect, useState} from 'react';
import Body from '../atoms/body';
import CTAButton from '../atoms/buttons/cta-button';
import ParagraphHeroImage from '../paragraphs/paragraph--hero-image';
import ParagraphSection from '../paragraphs/paragraph--section';

interface NodeArticleProps {
    node: DrupalNode;
    className?: string;
}

export const NodeArticle = ({node, className, ...props}: NodeArticleProps) => {
    const {t} = useTranslation();
    const [filters, setFilters] = useState('');

    const sections = node?.field_contents?.filter((child: DrupalNode) => child.type === PARAGRAPH_TYPES.section) ?? [];

    useEffect(() => {
        if (sessionStorage) {
            setFilters(getFilters(STORAGE_KEYS.articleFilters));
        }
    }, []);

    return (
        <article className={cn(className)} {...props}>
            {node.field_image_paragraph && (
                <ParagraphHeroImage
                    display="full"
                    paragraph={node.field_image_paragraph}
                    backButton={{
                        url: getFiltersLink(node.field_category?.field_link?.url, filters),
                        title: `${t('backTo')} ${node.field_category?.field_link?.title ?? node.field_category?.name}`,
                        className: 'inline lg:hidden',
                        visible: true,
                    }}
                />
            )}
            {node.field_body?.processed && (
                <div className="narrow-container container relative py-[90px]" id="summary">
                    <Body className={cn('text-center text-[27px] leading-[40px] [text-wrap:balance]', 'prose')} value={node.field_body?.processed} />
                </div>
            )}
            {sections.map((paragraph: DrupalParagraph, index) => {
                const nodeChildren = node.field_contents.filter((child: DrupalNode) => child.behavior_settings.layout_paragraphs.parent_uuid === paragraph.id);
                sections[index].allNodes = nodeChildren;
                return (
                    <ParagraphSection
                        allSections={sections}
                        currentSectionIndex={index}
                        paragraph={paragraph}
                        key={paragraph.id}
                        nodeChildren={nodeChildren}
                        containerClassName="narrow-container"
                    />
                );
            })}
            <div className="flex items-center justify-center bg-background py-[50px] lg:py-[80px]">
                <CTAButton href={getFiltersLink(node.field_category?.field_link?.url, filters)} type="back" backgroundColor={COLOR_VARIANTS['bg-white']}>
                    {t('backTo')} {node.field_category?.field_link?.title ?? node.field_category?.name}
                </CTAButton>
            </div>
        </article>
    );
};
