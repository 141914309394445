import type {DrupalRedirect, LayoutElements} from '@/lib/interfaces';
import type {LanguageLinks} from '@/lib/types';
import type {GetStaticPathsContext, GetStaticPathsResult, GetStaticPropsContext, GetStaticPropsResult} from 'next';

import {Meta} from '@/components/meta';
import {NodeArticle} from '@/components/nodes/node--article';
import {NodeBasicPage} from '@/components/nodes/node--basic-page';
import {NodeProduct} from '@/components/nodes/node--product';
import {NavbarProvider} from '@/context/NavbarContext';
import {NODE_TYPES} from '@/lib/constants';
import {getNodeTranslatedVersions} from '@/lib/get-node-translated-versions';
import {createLanguageLinks} from '@/lib/language-links-context';
import {addDynamicFilters, preloadViews} from '@/lib/views';
import {loadWebformsParagraphs} from '@/lib/webforms';
import {DehydratedState} from '@tanstack/react-query';
import {Layout} from 'components/layout';
import {drupal, getDrupalParams} from 'lib/drupal';
import {getLayoutElements} from 'lib/get-layout-elements';
import {DrupalNode} from 'next-drupal';
import {serverSideTranslations} from 'next-i18next/serverSideTranslations';

const RESOURCE_TYPES = [NODE_TYPES.page, NODE_TYPES.article, NODE_TYPES.product];

interface NodePageProps extends LayoutElements {
    resource: DrupalNode | undefined;
    languageLinks: LanguageLinks | undefined;
    dehydratedState?: DehydratedState;
    page?: number;
}

export default function NodePage({resource, menus, blocks, page}: NodePageProps) {
    if (!resource) return null;
    return (
        <NavbarProvider navbarConfig={resource.field_navbar}>
            <Meta title={resource.title} metatags={resource.metatag} path={resource.path} page={page} />
            <Layout menus={menus} blocks={blocks}>
                {resource.type === NODE_TYPES.page && <NodeBasicPage node={resource} page={page} />}
                {resource.type === NODE_TYPES.article && <NodeArticle node={resource} />}
                {resource.type === NODE_TYPES.product && <NodeProduct node={resource} />}
            </Layout>
        </NavbarProvider>
    );
}

export async function getStaticPaths(context: GetStaticPathsContext): Promise<GetStaticPathsResult> {
    return {
        paths: await drupal.getStaticPathsFromContext(RESOURCE_TYPES, context),
        fallback: 'blocking',
    };
}

export async function getStaticProps(context: GetStaticPropsContext): Promise<GetStaticPropsResult<NodePageProps>> {
    const originalContext = {...context};
    const {locale, defaultLocale, preview} = context;
    let page: number = 0;
    const lastSlug = context.params.slug.at(-1);
    const isNumber = /^\d+$/.test(lastSlug);
    if (isNumber && Array.isArray(context.params.slug)) {
        page = Number(lastSlug);
        page = page > 0 ? page - 1 : 0;
        context.params.slug.pop();
    }
    const path = await drupal.translatePathFromContext(context, {
        pathPrefix: locale === defaultLocale ? locale : '/',
    });

    if (!path || (page !== 0 && path.jsonapi.resourceName !== NODE_TYPES.page)) {
        return {
            notFound: true,
        };
    }

    // Check for redirect.
    if (path.redirect?.length) {
        const redirect: DrupalRedirect = path.redirect[0];
        if (!redirect.from_domain) {
            return {
                redirect: {
                    destination: redirect.to,
                    permanent: redirect.status === '301',
                },
            };
        }
        return {
            notFound: true,
        }
    }

    const params = getDrupalParams(path.jsonapi.resourceName as NODE_TYPES);
    const resource = await drupal.getResourceFromContext<DrupalNode>(path, context, {
        params: params.getQueryObject(),
        withAuth: true,
    });
    // At this point, we know the path exists and it points to a resource.
    // If we receive an error, it means something went wrong on Drupal.
    // We throw an error to tell revalidation to skip this for now.
    // Revalidation can try again on next request.
    if (!resource) {
        throw new Error(`Failed to fetch resource: ${path.jsonapi.individual}`);
    }

    // If we're not in preview mode and the resource is not published,
    // Return page not found.
    if (!preview && resource?.status === false) {
        return {
            notFound: true,
        };
    }

    const nodeTranslations = await getNodeTranslatedVersions(resource, context, drupal);
    const languageLinks = await createLanguageLinks(nodeTranslations, context, drupal);

    let preloadedViews: DehydratedState | undefined;
    if (resource.field_contents) {
        await addDynamicFilters(resource.field_contents, context, drupal);
        await loadWebformsParagraphs(resource.field_contents, context, drupal);
        preloadedViews = await preloadViews(resource.field_contents, context, page, drupal);
    }
    return {
        props: {
            resource,
            ...(await getLayoutElements(originalContext)),
            ...(await serverSideTranslations(locale, ['common'])),
            languageLinks,
            ...(preloadedViews ? {dehydratedState: preloadedViews} : {}),
            ...(page ? {page: page} : {}),
        },
    };
}
