import type {DrupalMedia, DrupalNode} from 'next-drupal';

import useScreenSize from '@/hooks/useScreenSize';
import {PRODUCT_FIELD_TYPES, SCREENS, STORAGE_KEYS} from '@/lib/constants';
import {MotionConfig, motion} from 'framer-motion';
import {absoluteUrl, cn, getFilters, getFiltersLink} from 'lib/utils';
import {useTranslation} from 'next-i18next';
import Image from 'next/image';
import React, {ReactElement, useEffect, useRef, useState} from 'react';
import {A11y, Autoplay, EffectFade, Pagination} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';
import CTAButton from '../atoms/buttons/cta-button';
import Heading from '../atoms/heading';
import Text from '../atoms/text';
import {Accordion, AccordionContent, AccordionItem, AccordionTrigger} from '../molecules/accordion/accordion';
import {ListContent, ListHeader, ListItem, ListRoot, ListSeparator} from '../molecules/list/list';
import {Tabs, TabsContent, TabsList, TabsTrigger} from '../molecules/tabs/tabs';
import VideoPlayer from '../molecules/video-player/video-player';

interface NodeProductProps {
    node: DrupalNode;
}

interface ImageProps {
    src: string;
    width?: number;
    height?: number;
    alt?: string;
}

interface ProductBenefitProps {
    imgObject: ImageProps;
    title: string;
    description: string;
}

export const NodeProduct = ({node, ...props}: NodeProductProps) => {
    const TAB_TYPES = {
        composition: 'composition',
        feedingRecommendation: 'feedingRecommendation',
        ingredientsAndAdditives: 'ingredientsAndAdditives',
    } as const;

    const {t} = useTranslation();
    const screen = useScreenSize();
    const tabOffsetRef = useRef(null);
    const [filters, setFilters] = useState('');

    useEffect(() => {
        if (sessionStorage) {
            setFilters(getFilters(STORAGE_KEYS.productFilters));
        }
    }, []);

    const getImage = (type: string, isField?: boolean): ImageProps => {
        let image = null;
        if (isField) {
            image = node[type]?.field_media_image;
        } else {
            image = node.field_assets?.filter((asset: DrupalMedia) => asset.field_type === type)[0]?.field_media_image;
        }

        if (!image) {
            return null;
        }
        const {alt, width, height} = image?.resourceIdObjMeta ?? {};

        if (image?.uri?.url) {
            return {
                src: absoluteUrl(image?.uri?.url),
                width,
                height,
                alt,
            };
        }

        return null;
    };

    const getProductImage = (): ImageProps => getImage(PRODUCT_FIELD_TYPES.productPicture);

    const getPictogramImage = (index: number): ImageProps => getImage(`field_piktogramm_image_${index}`, true);

    const ingredientsAndAdditivesDescription = `
    <table class="max-w-[16rem] align-last-td-right"> 
      <tbody>
        <tr>
            <td style="text-align:left;"><strong>${t('analyticalConstituents')}</strong></td>
        </tr>
        ${node?.field_protein ? `<tr><td><strong>${t('protein')}</strong></td><td>${node.field_protein}</td></tr>` : ''}
        ${node?.field_fat_content ? `<tr><td><strong>${t('fatContent')}</strong></td><td>${node.field_fat_content}</td></tr>` : ''}
        ${node?.field_crude_fibre ? `<tr><td><strong>${t('crudeFibre')}</strong></td><td>${node.field_crude_fibre}</td></tr>` : ''}
        ${node?.field_crude_ash ? `<tr><td><strong>${t('crudeAsh')}</strong></td><td>${node.field_crude_ash}</td></tr>` : ''}
        ${node?.field_calcium ? `<tr><td><strong>${t('calcium')}</strong></td><td>${node.field_calcium}</td></tr>` : ''}
        ${node?.field_magnesium ? `<tr><td><strong>${t('magnesium')}</strong></td><td>${node.field_magnesium}</td></tr>` : ''}
        ${node?.field_potassium ? `<tr><td><strong>${t('potassium')}</strong></td><td>${node.field_potassium}</td></tr>` : ''}
        ${node?.field_phosphorus ? `<tr><td><strong>${t('phosphorus')}</strong></td><td>${node.field_phosphorus}</td></tr>` : ''}
        ${node?.field_sodium ? `<tr><td><strong>${t('sodium')}</strong></td><td>${node.field_sodium}</td></tr>` : ''}
      </tbody>
    </table>
    `;

    const hasIngredientsAndAdditivesDescription =
        node?.field_protein ||
        node?.field_fat_content ||
        node?.field_crude_ash ||
        node?.field_calcium ||
        node?.field_magnesium ||
        node?.field_potassium ||
        node?.field_phosphorus ||
        node?.field_sodium;

    const isPictogramVisible = (index: number): boolean =>
        !!(getPictogramImage(index) || node[`field_piktogramm_header_${index}`] || node[`field_piktogramm_text_${index}`]);

    const TABS = [
        node?.field_composition_header || node?.field_composition
            ? {
                  type: TAB_TYPES.composition,
                  title: t(TAB_TYPES.composition),
                  subtitle: node.field_composition_header,
                  description: node.field_composition,
                  image: getProductImage(),
              }
            : null,
        node?.field_feeding_recommendations || node?.field_feeding_recommendations_ds
            ? {
                  type: TAB_TYPES.feedingRecommendation,
                  title: t(TAB_TYPES.feedingRecommendation),
                  table: node.field_feeding_recommendations,
                  description: node.field_feeding_recommendations_ds,
              }
            : null,
        hasIngredientsAndAdditivesDescription
            ? {
                  type: TAB_TYPES.ingredientsAndAdditives,
                  title: t(TAB_TYPES.ingredientsAndAdditives),
                  table: ingredientsAndAdditivesDescription,
              }
            : null,
    ].filter(Boolean);

    const PICTOGRAMS = [
        isPictogramVisible(1)
            ? {
                  image: getPictogramImage(1),
                  title: node.field_piktogramm_header_1,
                  description: node.field_piktogramm_text_1,
              }
            : null,
        isPictogramVisible(2)
            ? {
                  image: getPictogramImage(2),
                  title: node.field_piktogramm_header_2,
                  description: node.field_piktogramm_text_2,
              }
            : null,
        isPictogramVisible(3)
            ? {
                  image: getPictogramImage(3),
                  title: node.field_piktogramm_header_3,
                  description: node.field_piktogramm_text_3,
              }
            : null,
    ].filter(Boolean);

    const [activeTab, setActiveTab] = useState({title: TABS[0]?.title, index: 0, element: null});

    const renderSwiper = (isMobile: boolean): ReactElement => (
        <Swiper
            modules={[Autoplay, EffectFade, Pagination, A11y]}
            autoplay={{
                delay: 5000,
            }}
            pagination={{clickable: true}}
            centeredSlides
            spaceBetween={24}
            className={isMobile ? 'content-slider mb-12 !pb-12 lg:!hidden' : 'content-slider mb-12 !hidden !h-[200%] !w-full !min-w-[300px] !pb-12 lg:!block'}
        >
            {node.field_assets &&
                node.field_assets
                    .filter((asset: DrupalMedia) => asset.field_type === PRODUCT_FIELD_TYPES.packagingPicture)
                    .map((asset: DrupalMedia) => {
                        if (!asset.field_media_image) return null;
                        const {uri, resourceIdObjMeta} = asset.field_media_image;
                        const {alt, width, height} = resourceIdObjMeta;
                        return (
                            <SwiperSlide key={asset.id}>
                                <Image
                                    className={cn(screen.name === SCREENS.desktop.name ? 'h-[620px]' : 'h-[365px]', 'w-full rounded-xl object-contain')}
                                    src={absoluteUrl(uri.url)}
                                    alt={alt ?? asset.name}
                                    width={+width}
                                    height={+height}
                                />
                            </SwiperSlide>
                        );
                    })}
        </Swiper>
    );

    const tabListRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const handleResize = () => {
            if (TABS?.length && tabListRef.current?.children) {
                const currentTab = [...tabListRef.current?.children].find(child => child.getAttribute('data-state') === 'active');
                if (currentTab && currentTab instanceof HTMLElement) {
                    if (!activeTab || tabOffsetRef.current !== currentTab.offsetLeft) {
                        tabOffsetRef.current = currentTab.offsetLeft;

                        setActiveTab(prevActiveTab => ({...prevActiveTab, element: currentTab}));
                    }
                } else {
                    setActiveTab({index: 0, title: TABS[0].title, element: tabListRef.current?.children[0]});
                }
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [TABS, activeTab]);

    const renderTabs = (): ReactElement =>
        screen.name !== SCREENS.desktop.name ? (
            <Accordion type="single" className="lg:hidden" collapsible>
                {TABS.map(({title, description, table, subtitle, image, type}, index) => {
                    return (
                        <AccordionItem key={index} value={title}>
                            <AccordionTrigger className="px-4" onClick={e => setActiveTab({title, index, element: e.target})}>
                                {title}
                            </AccordionTrigger>
                            <AccordionContent className="px-4" forceMount>
                                {image && (
                                    <Image
                                        alt={image.alt}
                                        src={image.src}
                                        width={+image.width}
                                        height={+image.height}
                                        className="mx-auto h-48 w-full max-w-[400px] rounded-xl object-contain"
                                    />
                                )}
                                <div className={type === TAB_TYPES.ingredientsAndAdditives ? 'flex items-center justify-center pt-4' : 'pt-4'}>
                                    {subtitle && (
                                        <Heading type="h6" className={cn(image ? 'my-8' : 'mb-8', 'text-base font-semibold')}>
                                            {subtitle}
                                        </Heading>
                                    )}
                                    {(table || description) && (
                                        <div className="flex flex-col">
                                            {table && (
                                                <div
                                                    className={cn('product-table', {
                                                        'mb-12': description,
                                                    })}
                                                    dangerouslySetInnerHTML={{
                                                        __html: table,
                                                    }}
                                                />
                                            )}
                                            {description && (
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: description,
                                                    }}
                                                />
                                            )}
                                        </div>
                                    )}
                                </div>
                            </AccordionContent>
                        </AccordionItem>
                    );
                })}
            </Accordion>
        ) : (
            <Tabs value={activeTab.title} className="hidden overflow-hidden lg:block">
                <TabsList ref={tabListRef} className="relative">
                    {TABS.map(({title}, index) => {
                        return (
                            <TabsTrigger
                                onFocus={e => setActiveTab({title, index, element: e.currentTarget})}
                                onClick={e => setActiveTab({title, index, element: e.currentTarget})}
                                key={index}
                                value={title}
                            >
                                <span className="mr-2 text-primary">{++index}.</span>
                                {title}
                            </TabsTrigger>
                        );
                    })}
                    <div
                        style={{
                            transform: `translateX(${activeTab?.element?.offsetLeft - 25}px) translateY(13px)`,
                            width: `${activeTab?.element?.clientWidth + 50}px`,
                            height: 66,
                        }}
                        className={cn('z-1 absolute bottom-0 left-0 h-full transform rounded-xl bg-background px-5 py-3 transition-transform')}
                    />
                </TabsList>
                <MotionConfig
                    transition={{
                        ease: 'easeInOut',
                    }}
                >
                    {TABS.map(({title, description, table, subtitle, image, type}, index) => {
                        return (
                            <motion.div
                                key={`${index}${activeTab.title}`}
                                initial={{
                                    x: '100%',
                                    display: 'none',
                                }}
                                animate={{
                                    x: 0,
                                    display: 'block',
                                }}
                            >
                                <TabsContent forceMount key={index} value={title} className={cn('duration-700  data-[state="inactive"]:hidden')}>
                                    <div
                                        className={cn('flex items-center justify-center gap-24', {
                                            'mx-28': type === TAB_TYPES.composition || type === TAB_TYPES.ingredientsAndAdditives,
                                            'mx-20': type === TAB_TYPES.feedingRecommendation,
                                        })}
                                    >
                                        <div className={type === TAB_TYPES.composition || type === TAB_TYPES.feedingRecommendation ? 'w-full' : ''}>
                                            {subtitle && (
                                                <Heading type="h6" className="text-base font-semibold">
                                                    {title}: {subtitle}
                                                </Heading>
                                            )}
                                            {(table || description) && (
                                                <div className="flex">
                                                    {table && (
                                                        <div
                                                            className={cn({
                                                                'mr-20': description,
                                                                'product-table':
                                                                    type === TAB_TYPES.feedingRecommendation || type === TAB_TYPES.ingredientsAndAdditives,
                                                                'w-7/12': type === TAB_TYPES.feedingRecommendation,
                                                            })}
                                                            dangerouslySetInnerHTML={{
                                                                __html: table,
                                                            }}
                                                        />
                                                    )}
                                                    {description && (
                                                        <div
                                                            className={cn({
                                                                'max-w-[500px]': table,
                                                                'w-5/12': type === TAB_TYPES.feedingRecommendation,
                                                            })}
                                                            dangerouslySetInnerHTML={{
                                                                __html: description,
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                        {image && (
                                            <Image
                                                alt={image.alt}
                                                src={image.src}
                                                width={+image.width}
                                                height={+image.height}
                                                className="h-96 w-full max-w-[600px] rounded-xl object-contain"
                                            />
                                        )}
                                    </div>
                                </TabsContent>
                            </motion.div>
                        );
                    })}
                </MotionConfig>
            </Tabs>
        );

    return (
        <div className="bg-white lg:w-full">
            <article className="wide-container mx-auto bg-white" {...props}>
                <CTAButton
                    href={getFiltersLink(node.field_category?.field_link?.url, filters)}
                    type="back"
                    textColor="text-black"
                    className="my-12 font-normal"
                />
                <div className="justify-between lg:flex">
                    <div className="space-y-16 lg:max-w-[630px]">
                        <div>
                            {node.title && (
                                <Heading type="h1" className="mb-[9px] text-center font-bold lg:text-left">
                                    {node.title}
                                </Heading>
                            )}
                            {node.field_headline && <Text className="text-center lg:text-left" html={node.field_headline} />}
                        </div>

                        {node.field_short_description && <Text className="text-center lg:text-left" html={node.field_short_description} />}

                        {screen.name !== SCREENS.desktop.name && renderSwiper(true)}

                        <div className="my-10 space-y-10">
                            {node.field_bulletpoints && (
                                <div className="prose">
                                    <ul>
                                        {node.field_bulletpoints.map((value, index) => (
                                            <li key={index}>{value}</li>
                                        ))}
                                    </ul>
                                </div>
                            )}

                            <div className="flex gap-4">
                                <Heading type="h6" className="inline font-bold">
                                    {`${t('packagingSize')}:`}
                                </Heading>
                                {node.field_packaging_sizes?.map((packagingSize, index) => <span key={`${index}${packagingSize}`}>{packagingSize}</span>)}
                            </div>
                        </div>
                    </div>

                    {screen.name === SCREENS.desktop.name && <div className="h-full max-w-[50%]">{renderSwiper(false)}</div>}
                </div>

                {node.field_product_video && <VideoPlayer containerClassName="mx-auto my-8 max-w-2xl" url={node.field_product_video} title={node.title} />}

                <div className="py-8">{renderTabs()}</div>

                {PICTOGRAMS.length ? (
                    <div className="py-8">
                        <ListRoot>
                            <ListHeader>
                                {node.title} <strong>{t('benefits')}</strong>
                            </ListHeader>
                            <ListContent>
                                {PICTOGRAMS.map(({image, title, description}, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            {index !== 0 && <ListSeparator />}
                                            <ListItem>
                                                <ProductBenefit
                                                    imgObject={image?.width && image?.height ? image : null}
                                                    title={title}
                                                    description={description}
                                                />
                                            </ListItem>
                                        </React.Fragment>
                                    );
                                })}
                            </ListContent>
                        </ListRoot>
                    </div>
                ) : null}
            </article>
        </div>
    );
};

const ProductBenefit = ({imgObject, title, description}: ProductBenefitProps) => (
    <div>
        <div className="mb-12 flex min-h-[56px] items-center gap-4">
            {imgObject && <Image {...imgObject} alt={title} className="h-14 w-16 object-contain" />}
            <h2 className="text-[18px] font-semibold uppercase leading-[25px] lg:text-[20px] lg:leading-[27px]">{title}</h2>
        </div>
        <p className="leading-[25px]">{description}</p>
    </div>
);
