import Heading from 'components/atoms/heading';

export const ListRoot = ({children}) => <div className="flex flex-col rounded-xl bg-primary pt-8 lg:gap-[60px] lg:p-20 lg:pt-[60px]">{children}</div>;

export const ListHeader = ({children}) => (
    <Heading type="h3" className="pl-8 text-[20px] font-semibold leading-[26px] lg:pl-0 lg:text-center lg:text-[40px] lg:font-light lg:leading-[50px]">
        {children}
    </Heading>
);

export const ListItem = ({children}) => (
    <li tabIndex={0} className="flex-1 p-8 lg:p-0">
        {children}
    </li>
);

export const ListContent = ({children}) => <ul className="flex flex-col flex-wrap justify-between lg:flex-row lg:gap-16">{children}</ul>;

export const ListSeparator = () => <div aria-hidden className="block w-full border-b border-black/20 lg:w-px lg:border-r" />;
